import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Typography } from "@mui/material"

import { BsArrowRight } from "react-icons/bs"

import Layout from "../components/layout"
// import MailIcon from "../images/fluent_mail-28-filledemailicon.svg"
// import OfficeIcon from "../images/heroicons-solid_office-buildingstreeticon.svg"
import ContactForm from "../components/ContactForm"
import FullWidthStripe from "../components/FullWidthStripe"
import Link from "../components/Link"
import FeaturedResources from "../components/featured-resources"

const NotFound = () => {
  const {
    allStrapiJobOpening: { nodes: jobOpenings },
    allStrapiArticle: { nodes: featuredArticles },
  } = useStaticQuery(jobOpeningsQuery)

  const filteredJobOpenings = jobOpenings.filter(
    ({ header }) => header !== "dummy"
  )

  return (
    <Layout isContactPage>
      <Box>
        <FullWidthStripe
          bgcolor="#fafafa"
          py={{ xs: 4, lg: 10 }}
          px={{ xs: 2, sm: 5, lg: 10 }}
        >
          <Box
            width={{ lg: 800 }}
            mx="auto"
            alignItems={{ xs: "flex-start", lg: "center" }}
            display="flex"
            gap={{ xs: 5, md: 12.5 }}
            flexDirection={{ xs: "column" }}
          >
            <Box display="flex" flexDirection="column" flex={2}>
              <Typography
                variant="overline"
                color="primary"
                fontWeight={500}
                gutterBottom
              >
                Careers
              </Typography>
              <Typography variant="h1" sx={{ mb: 3 }}>
                Want To Join Our Team?
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We are always on the look for the best people in the industry to
                work with. If you believe that you would be a good fit for our
                team, fill out the provided form, or send us an e-mail with your
                CV. We look forward to meeting you!
              </Typography>
            </Box>

            {filteredJobOpenings.length !== 0 && (
              <Box display="flex" flexDirection="column" gap={4} width={1}>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  Current Job Openings
                </Typography>

                {[...filteredJobOpenings].map(({ header, strapi_id }) => (
                  <Link key={strapi_id} href={`/job-opening/${strapi_id}`}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      pb={{ xs: 2, md: 4 }}
                      borderBottom="1px solid #9d9d9d"
                    >
                      <Typography variant="body1" fontWeight={900}>
                        {header}
                      </Typography>

                      <BsArrowRight size={36} color="#545454" />
                    </Box>
                  </Link>
                ))}
              </Box>
            )}
            <ContactForm
              hasCV
              postURI="careers"
              fileInputLabel="Add CV"
              fileFieldName="CV"
            />
          </Box>
        </FullWidthStripe>

        <FeaturedResources
          resourceURI="/articles"
          overline="Case studies"
          title="Featured resources"
          resources={featuredArticles}
          bgcolor="white"
        />
      </Box>
    </Layout>
  )
}

const jobOpeningsQuery = graphql`
  query JobOpeningQuery {
    allStrapiJobOpening {
      nodes {
        header
        strapi_id
      }
    }

    allStrapiArticle(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      nodes {
        strapi_id
        readTime
        publishedAt
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            url
          }
        }
      }
    }
  }
`

export default NotFound
